import { T } from "@/locales";

export const columns = [
  {
    title: T("序号"),
    dataIndex: "index",
    customRender: (value, item, index) => index + 1
  },
  {
    title: T("名称"),
    dataIndex: "name",
    sorter: true,
  },
  {
    title: T("编号"),
    dataIndex: "number",
    sorter: true,
  },
  {
    title: T("条码"),
    dataIndex: "barcode",
  },
  {
    title: T("规格"),
    dataIndex: "spec",
  },
  {
    title: T("单位"),
    dataIndex: "unit",
  },
  {
    title: T("操作"),
    dataIndex: "action",
    scopedSlots: { customRender: "action" },
  },
];
